import React from 'react';
import { DropdownItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { EngagementTab, EngagementContainer, NewUserCard, EmployeeGrid, UserButton } from '../EngagementSection/styles';
import { SplitEngagementSection, CommonContainer, FlexBox, ContactIcon, GridBox, ImageAndProfile, HeaderEmployee } from './styles'
import { StyledSvg } from '../AddWellnessInitiative/styles';
import { UserName, EditProfile, AoiBar, LevelText, StarContainer, StarIcon } from '../SideBar/styles';
import { CustomDropDown } from '../PeopleHome/styles';
import { HeaderContainer, HeaderTitle, HeaderImage } from '../HealthTab/styles';

import { WorkAnniversary, ThankYou, Birthday, JobWellDone, Congrats } from '../SvgComponents';

const contactDetails = [
  {
    name: 'Office Tel',
    details: "(404) 192-5945 Ext: 194"
  },
  {
    name: 'Mobile',
    details: '(472) 276-0193'
  },
  {
    name: 'email',
    details: 'dbaylidge@company.com'
  },
  {
    name: 'city',
    details: 'New York'
  },
  {
    name: 'State',
    details: 'New York'
  }
];

const badgeDetails = [
  {
    value: 1,
    issue: 'Health',
    issueDescription: 'Weight Lose'
  },
  {
    value: 2,
    issue: 'Behavioral',
    issueDescription: 'Quit Smoking'
  }
];

const otherGoalsData = [
  {
    name: 'Adventures',
    svg: "M18.07 3.93C19.96 5.82 21 8.33 21 11C21 13.67 19.96 16.18 18.07 18.07C16.18 19.96 13.67 21 11 21C8.33 21 5.82 19.96 3.93 18.07C2.04 16.18 1 13.67 1 11C1 8.33 2.04 5.82 3.93 3.93C5.82 2.04 8.33 1 11 1C13.67 1 16.18 2.04 18.07 3.93ZM17.19 17.19C18.42 15.95 19.24 14.42 19.57 12.75C19.31 13.14 19.06 13.28 18.9 12.42C18.74 11 17.44 11.9 16.63 11.4C15.77 11.98 13.84 10.28 14.16 12.2C14.67 13.07 16.9 11.04 15.79 12.87C15.08 14.16 13.2 16.99 13.44 18.47C13.47 20.61 11.25 18.91 10.49 18.2C9.97 16.78 10.31 14.29 8.97 13.59C7.51 13.53 6.25 13.39 5.68 11.76C5.34 10.59 6.05 8.85 7.3 8.58C9.13 7.43 9.79 9.93 11.51 9.98C12.04 9.42 13.5 9.24 13.62 8.62C12.49 8.42 15.05 7.67 13.51 7.24C12.66 7.34 12.11 8.12 12.56 8.78C10.92 9.17 10.87 6.4 9.28 7.28C9.24 8.65 6.7 7.72 8.4 7.44C8.99 7.19 7.45 6.45 8.28 6.58C8.69 6.56 10.07 6.08 9.69 5.75C10.46 5.28 11.1 6.89 11.85 5.72C12.39 4.81 11.62 4.65 10.95 5.1C10.56 4.68 11.62 3.75 12.55 3.35C12.87 3.22 13.16 3.15 13.39 3.17C13.86 3.71 14.73 3.8 14.77 3.1C13.61 2.54 12.33 2.25 11 2.25C9.09 2.25 7.28 2.85 5.78 3.98C6.18 4.16 6.41 4.39 6.02 4.68C5.72 5.58 4.49 6.79 3.42 6.62C2.86 7.58 2.49 8.64 2.34 9.76C3.24 10.05 3.44 10.64 3.25 10.84C2.79 11.24 2.51 11.81 2.36 12.43C2.66 14.22 3.5 15.88 4.81 17.19C6.46 18.84 8.66 19.75 11 19.75C13.34 19.75 15.53 18.84 17.19 17.19Z",
    viewBox: '0 0 38 22',
    fill: '#159fc9',
    value: 29
  },
  {
    value: 15,
    name: 'Challenges',
    viewBox: '0 0 41 22',
    fill: '#fd5d65',
    svg: 'M13.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM19.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z'
  },
  {
    name: 'Events',
    viewBox: '0 0 40 25',
    fill: '#fbad19',
    value: 97,
    svg: 'M15.4 18L1.6 18C0.7 18 0 17.3 0 16.4L0 3.6C0 2.7 0.7 2 1.5 2L1.5 4.2C1.5 5.2 2.3 6 3.2 6L4.3 6C5.3 6 6.1 5.2 6.1 4.2L6.1 2 10.9 2 10.9 4.2C10.9 5.2 11.7 6 12.7 6L13.8 6C14.7 6 15.5 5.2 15.5 4.2L15.5 2C16.3 2 17 2.7 17 3.6L17 16.4C17 17.3 16.3 18 15.4 18ZM15 9C15 8.6 14.7 8.3 14.3 8.3L2.7 8.3C2.3 8.3 2 8.6 2 9L2 15.6C2 16 2.3 16.3 2.7 16.3L14.3 16.3C14.7 16.3 15 16 15 15.6L15 9ZM12.6 15.3L11.3 15.3C11 15.3 10.9 15.1 10.9 14.9L10.9 13.5C10.9 13.3 11 13.1 11.3 13.1L12.6 13.1C12.8 13.1 13 13.3 13 13.5L13 14.9C13 15.1 12.8 15.3 12.6 15.3ZM12.6 11.8L11.3 11.8C11 11.8 10.9 11.6 10.9 11.4L10.9 9.9C10.9 9.7 11 9.5 11.3 9.5L12.6 9.5C12.8 9.5 13 9.7 13 9.9L13 11.4C13 11.6 12.8 11.8 12.6 11.8ZM9.2 15.3L7.8 15.3C7.6 15.3 7.4 15.1 7.4 14.9L7.4 13.5C7.4 13.3 7.6 13.1 7.8 13.1L9.2 13.1C9.4 13.1 9.6 13.3 9.6 13.5L9.6 14.9C9.6 15.1 9.4 15.3 9.2 15.3ZM9.2 11.8L7.8 11.8C7.6 11.8 7.4 11.6 7.4 11.4L7.4 9.9C7.4 9.7 7.6 9.5 7.8 9.5L9.2 9.5C9.4 9.5 9.6 9.7 9.6 9.9L9.6 11.4C9.6 11.6 9.4 11.8 9.2 11.8ZM5.7 15.3L4.4 15.3C4.2 15.3 4 15.1 4 14.9L4 13.5C4 13.3 4.2 13.1 4.4 13.1L5.7 13.1C6 13.1 6.1 13.3 6.1 13.5L6.1 14.9C6.1 15.1 6 15.3 5.7 15.3ZM5.7 11.8L4.4 11.8C4.2 11.8 4 11.6 4 11.4L4 9.9C4 9.7 4.2 9.5 4.4 9.5L5.7 9.5C6 9.5 6.1 9.7 6.1 9.9L6.1 11.4C6.1 11.6 6 11.8 5.7 11.8ZM13.7 4.8L12.7 4.8C12.3 4.8 12.1 4.5 12.1 4.2L12.1 0.6C12.1 0.3 12.3 0 12.7 0L13.7 0C14.1 0 14.3 0.3 14.3 0.6L14.3 4.2C14.3 4.5 14.1 4.8 13.7 4.8ZM4.3 4.8L3.2 4.8C2.9 4.8 2.6 4.5 2.6 4.2L2.6 0.6C2.6 0.3 2.9 0 3.2 0L4.3 0C4.6 0 4.9 0.3 4.9 0.6L4.9 4.2C4.9 4.5 4.6 4.8 4.3 4.8Z'
  },
  {
    name: 'Lunch and Learn',
    fill: '#93bf3d',
    svg: 'M10.51,7.37c0.4,-0.65 1.04,-1.57 1.84,-2.46h0c0.68,-0.75 2.01,-1.72 2.01,-1.72c0.2,-0.15 0.18,-0.35 -0.05,-0.45l-1.61,-0.72c-0.23,-0.1 -0.54,-0.03 -0.69,0.17c0,0 -1.19,1.65 -2.59,5.15c-4.32,-1.89 -8.43,0.76 -8.43,5.35c0,4.8 3.83,12.1 9,9.9c5.45,2.28 9.01,-5.09 9.01,-9.9c0,-4.64 -3.69,-7.36 -8.5,-5.31zM9.54,5.81c0.25,-0.02 0.47,-0.23 0.49,-0.47c0,0 0.16,-1.88 -1.2,-3.19c-1.36,-1.31 -3.3,-1.15 -3.3,-1.15c-0.25,0.02 -0.47,0.23 -0.49,0.47c0,0 -0.16,1.88 1.19,3.19c1.36,1.31 3.3,1.15 3.3,1.15z',
    viewBox: '0 0 38 28',
    value: 120
  }
];
  
const recognitionData = [
  {
    name: 'Work Anniversary',
    value: 'May 22',
    fill: '#159fc9',
  },
  {
    name: 'Birthday',
    value: 'September 29',
    fill: '#fd5d65',
  },
  {
    name: 'Job Well Done',
    value: 15,
    fill: '#93bf3d',
  },
  {
    name: 'Thank You',
    value: 97,
    fill: '#159fc9',
  },
  {
    name: 'Congrats',
    value: 120,
    fill: '#fbad19',
  }
];

const wellnessInitiativeData = [
  {
    name: 'Biometric Screening',
    date: '1/06/2017-7/08/2018',
    svg: 'M8,3.44c-4.32,-7.48 -15.61,3.63 0,12.56c15.61,-8.93 4.32,-20.03 0,-12.56z',
    viewBox: '0 0 20 16',
    fill: '#fd5d65',
    tick: true
  },
  {
    name: 'Wellness Event',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 20 16',
    fill: '#159fc9',
    tick: false,
    svg: 'M11.53,1.14c-0.26,-0.22 -0.63,-0.22 -0.89,0c-0.56,0.49 -3.36,3.1 -3.36,6.34c0,3.24 2.8,5.85 3.36,6.34c0.13,0.11 0.29,0.17 0.45,0.17c0.16,0 0.32,-0.06 0.44,-0.17c0.56,-0.49 3.36,-3.1 3.36,-6.34c0,-3.24 -2.8,-5.85 -3.36,-6.34zM20.67,9.34c-0.16,-0.1 -0.56,-0.32 -1.09,-0.53c-0.17,-0.07 -0.35,0.04 -0.37,0.22c-0.16,1.24 -0.59,2.74 -1.66,3.83c-0.05,0.05 -0.1,0.1 -0.15,0.14c-0.22,0.2 -0.01,0.57 0.27,0.48c0.57,-0.19 1.06,-0.42 1.49,-0.7c1.45,-0.94 1.69,-2.12 1.78,-2.56c0.01,-0.03 0.01,-0.06 0.02,-0.08c0.1,-0.31 -0.02,-0.64 -0.29,-0.8zM18.58,6.33c-0.03,-0.34 -0.29,-0.6 -0.62,-0.63c-0.3,-0.02 -1.05,-0.06 -1.97,0.05c-0.17,0.02 -0.27,0.19 -0.23,0.35c0.11,0.41 0.17,0.84 0.17,1.3c0,2.56 -1.46,4.7 -2.65,6.03c-0.18,0.2 -0.01,0.52 0.25,0.47c1.11,-0.21 2.37,-0.66 3.32,-1.63c1.93,-1.98 1.78,-5.3 1.73,-5.95zM4.59,13.01c-0.05,-0.05 -0.1,-0.09 -0.15,-0.14c-1.07,-1.09 -1.5,-2.59 -1.66,-3.83c-0.02,-0.18 -0.21,-0.29 -0.37,-0.22c-0.53,0.21 -0.93,0.43 -1.09,0.53c-0.28,0.16 -0.4,0.5 -0.29,0.8c0,0.02 0.01,0.05 0.02,0.08c0.09,0.44 0.33,1.62 1.78,2.56c0.43,0.28 0.92,0.51 1.49,0.7c0.28,0.09 0.49,-0.27 0.27,-0.48zM8.71,13.45c-1.2,-1.33 -2.65,-3.47 -2.65,-6.03c0,-0.46 0.06,-0.9 0.17,-1.3c0.04,-0.17 -0.06,-0.33 -0.23,-0.35c-0.91,-0.12 -1.66,-0.08 -1.97,-0.05c-0.33,0.03 -0.59,0.29 -0.62,0.63c-0.05,0.65 -0.2,3.97 1.73,5.95c0.94,0.97 2.2,1.42 3.32,1.63c0.26,0.05 0.43,-0.27 0.25,-0.47z'
  },
  {
    name: 'Company Challenge',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 22 25',
    tick: true,
    fill: '#fbad19',
    svg: 'M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590, 13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080, 5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014, 10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z'
  },
  {
    name: 'Company 5k run',
    date: '1/06/2017',
    viewBox: '0 0 22 22',
    fill: '#fd5d65',
    tick: true,
    svg: 'M13.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM19.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z'
  },
  {
    name: 'Biometric Screening',
    date: '1/06/2017-7/08/2018',
    tick: true,
    svg: 'M8,3.44c-4.32,-7.48 -15.61,3.63 0,12.56c15.61,-8.93 4.32,-20.03 0,-12.56z',
    viewBox: '0 0 20 16',
    fill: '#fd5d65'
  },
  {
    name: 'Wellness Event',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 20 16',
    fill: '#159fc9',
    tick: false,
    svg: 'M11.53,1.14c-0.26,-0.22 -0.63,-0.22 -0.89,0c-0.56,0.49 -3.36,3.1 -3.36,6.34c0,3.24 2.8,5.85 3.36,6.34c0.13,0.11 0.29,0.17 0.45,0.17c0.16,0 0.32,-0.06 0.44,-0.17c0.56,-0.49 3.36,-3.1 3.36,-6.34c0,-3.24 -2.8,-5.85 -3.36,-6.34zM20.67,9.34c-0.16,-0.1 -0.56,-0.32 -1.09,-0.53c-0.17,-0.07 -0.35,0.04 -0.37,0.22c-0.16,1.24 -0.59,2.74 -1.66,3.83c-0.05,0.05 -0.1,0.1 -0.15,0.14c-0.22,0.2 -0.01,0.57 0.27,0.48c0.57,-0.19 1.06,-0.42 1.49,-0.7c1.45,-0.94 1.69,-2.12 1.78,-2.56c0.01,-0.03 0.01,-0.06 0.02,-0.08c0.1,-0.31 -0.02,-0.64 -0.29,-0.8zM18.58,6.33c-0.03,-0.34 -0.29,-0.6 -0.62,-0.63c-0.3,-0.02 -1.05,-0.06 -1.97,0.05c-0.17,0.02 -0.27,0.19 -0.23,0.35c0.11,0.41 0.17,0.84 0.17,1.3c0,2.56 -1.46,4.7 -2.65,6.03c-0.18,0.2 -0.01,0.52 0.25,0.47c1.11,-0.21 2.37,-0.66 3.32,-1.63c1.93,-1.98 1.78,-5.3 1.73,-5.95zM4.59,13.01c-0.05,-0.05 -0.1,-0.09 -0.15,-0.14c-1.07,-1.09 -1.5,-2.59 -1.66,-3.83c-0.02,-0.18 -0.21,-0.29 -0.37,-0.22c-0.53,0.21 -0.93,0.43 -1.09,0.53c-0.28,0.16 -0.4,0.5 -0.29,0.8c0,0.02 0.01,0.05 0.02,0.08c0.09,0.44 0.33,1.62 1.78,2.56c0.43,0.28 0.92,0.51 1.49,0.7c0.28,0.09 0.49,-0.27 0.27,-0.48zM8.71,13.45c-1.2,-1.33 -2.65,-3.47 -2.65,-6.03c0,-0.46 0.06,-0.9 0.17,-1.3c0.04,-0.17 -0.06,-0.33 -0.23,-0.35c-0.91,-0.12 -1.66,-0.08 -1.97,-0.05c-0.33,0.03 -0.59,0.29 -0.62,0.63c-0.05,0.65 -0.2,3.97 1.73,5.95c0.94,0.97 2.2,1.42 3.32,1.63c0.26,0.05 0.43,-0.27 0.25,-0.47z'
  },
  {
    name: 'Company Challenge',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 22 25',
    fill: '#fbad19',
    tick: true,
    svg: 'M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590, 13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080, 5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014, 10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z'
  },
  {
    name: 'Company 5k run',
    date: '1/06/2017',
    viewBox: '0 0 22 22',
    fill: '#fd5d65',
    tick: true,
    svg: 'M13.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM19.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z'
  },
  {
    name: 'Wellness Event',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 20 16',
    fill: '#159fc9',
    tick: false,
    svg: 'M11.53,1.14c-0.26,-0.22 -0.63,-0.22 -0.89,0c-0.56,0.49 -3.36,3.1 -3.36,6.34c0,3.24 2.8,5.85 3.36,6.34c0.13,0.11 0.29,0.17 0.45,0.17c0.16,0 0.32,-0.06 0.44,-0.17c0.56,-0.49 3.36,-3.1 3.36,-6.34c0,-3.24 -2.8,-5.85 -3.36,-6.34zM20.67,9.34c-0.16,-0.1 -0.56,-0.32 -1.09,-0.53c-0.17,-0.07 -0.35,0.04 -0.37,0.22c-0.16,1.24 -0.59,2.74 -1.66,3.83c-0.05,0.05 -0.1,0.1 -0.15,0.14c-0.22,0.2 -0.01,0.57 0.27,0.48c0.57,-0.19 1.06,-0.42 1.49,-0.7c1.45,-0.94 1.69,-2.12 1.78,-2.56c0.01,-0.03 0.01,-0.06 0.02,-0.08c0.1,-0.31 -0.02,-0.64 -0.29,-0.8zM18.58,6.33c-0.03,-0.34 -0.29,-0.6 -0.62,-0.63c-0.3,-0.02 -1.05,-0.06 -1.97,0.05c-0.17,0.02 -0.27,0.19 -0.23,0.35c0.11,0.41 0.17,0.84 0.17,1.3c0,2.56 -1.46,4.7 -2.65,6.03c-0.18,0.2 -0.01,0.52 0.25,0.47c1.11,-0.21 2.37,-0.66 3.32,-1.63c1.93,-1.98 1.78,-5.3 1.73,-5.95zM4.59,13.01c-0.05,-0.05 -0.1,-0.09 -0.15,-0.14c-1.07,-1.09 -1.5,-2.59 -1.66,-3.83c-0.02,-0.18 -0.21,-0.29 -0.37,-0.22c-0.53,0.21 -0.93,0.43 -1.09,0.53c-0.28,0.16 -0.4,0.5 -0.29,0.8c0,0.02 0.01,0.05 0.02,0.08c0.09,0.44 0.33,1.62 1.78,2.56c0.43,0.28 0.92,0.51 1.49,0.7c0.28,0.09 0.49,-0.27 0.27,-0.48zM8.71,13.45c-1.2,-1.33 -2.65,-3.47 -2.65,-6.03c0,-0.46 0.06,-0.9 0.17,-1.3c0.04,-0.17 -0.06,-0.33 -0.23,-0.35c-0.91,-0.12 -1.66,-0.08 -1.97,-0.05c-0.33,0.03 -0.59,0.29 -0.62,0.63c-0.05,0.65 -0.2,3.97 1.73,5.95c0.94,0.97 2.2,1.42 3.32,1.63c0.26,0.05 0.43,-0.27 0.25,-0.47z'
  },
  {
    name: 'Company Challenge',
    date: '1/06/2017-7/08/2018',
    viewBox: '0 0 22 25',
    fill: '#fbad19',
    tick: true,
    svg: 'M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590, 13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080, 5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014, 10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z'
  },
  {
    name: 'Company 5k run',
    date: '1/06/2017',
    viewBox: '0 0 22 22',
    fill: '#fd5d65',
    tick: true,
    svg: 'M13.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM19.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z'
  }
];

const employee = {
  name: 'Dylan baylidge',
  designation: 'Sales consultant',
  profileImage: '/public/images/employee/company-employee-4.png'
};

const TrainingCompleted = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Year = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2018];

const Quarter = ['First', 'Second', 'Third', 'Fourth'];

class EmployeeDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '',
      quarter: ''
    }
  }

  onSelectDropdown = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  getSvgComponent = (data) => {
    switch(data.name) {
    case 'Work Anniversary':
      return <WorkAnniversary color={data.fill} />
    case 'Birthday':
      return <Birthday color={data.fill} />
    case 'Job Well Done':
      return <JobWellDone color={data.fill} />
    case 'Thank You':
      return <ThankYou color={data.fill} />
    case 'Congrats':
      return <Congrats color={data.fill} />
    } 
  };
  
  setMenuItem = (data, id) => (
    data.map((details, index) => (
      <DropdownItem onClick={() => this.onSelectDropdown(id, details)} key={index}>{details}</DropdownItem>
    ))
  );

  getModelLogo = () => (
    <HeaderImage src="/public/images/Logo/modal-logo.png" />
  );

  renderHeader = (title, showLogo, showRightSection) => (
    <HeaderContainer>
      <div>
        {this.getModelLogo()}
        <HeaderTitle>
          {title}
          {showLogo && <img src="/public/images/info.png" alt="info" /> }
        </HeaderTitle>
      </div>
      {
        showRightSection &&
        <div>
          <span>2018</span>
        </div>
      }
    </HeaderContainer>
  );
  
  employeeDetails = (employee, TrainingCompleted) => (
    <div>
      <ImageAndProfile>
        <div>
          <img alt="profile" src={employee.profileImage} />
        </div>
        <UserName>{employee.name}</UserName>
        <EditProfile>
          <span>{employee.designation}</span>
        </EditProfile>
      </ImageAndProfile>
      <AoiBar>
        <div>
          <img src='/public/images/aoi_icons/cycling.png' />
        </div>
        <div>
          <img src='/public/images/aoi_icons/swimming.png' />
        </div>
        <div>
          <img src='/public/images/aoi_icons/fencing.png' />
        </div>
        <div>
          <img src='/public/images/aoi_icons/skating.png' />
        </div>
        <div>
          <img src='/public/images/aoi_icons/basketball.png' />
        </div>
      </AoiBar>
      <LevelText>LEVEL 5</LevelText>
      <StarContainer>
        { TrainingCompleted.map((data, index) => (
          <span key={index}>
            <StarIcon className="far fa-star" completed={(80 / 10) > data}/>
          </span>
        ))
        }
      </StarContainer>
    </div>
  );

  employeeInfoDashBoard = (employee, TrainingCompleted) => (
    <div className="boxShadow">
      {this.renderHeader('Employee Info')}
      <CommonContainer gridHeight="310px">
        { this.employeeDetails(employee, TrainingCompleted)}
      </CommonContainer>
    </div>
  );
  
  recognitionDashBoard = (recognitionData) => (
    <div className="boxShadow">
      {this.renderHeader('Recognition', true, true)}
      <CommonContainer padding="25px" gridHeight="310px">
        { this.showOtherGoals(recognitionData, '10px 0px')}
      </CommonContainer>
    </div>
  );

  socialIcon = () => (
    <CommonContainer padding="25px" borderTop>
      <ContactIcon bgColor="#4875B4">
        <FlexBox>
          <i className="fa fa-linkedin fa-lg" aria-hidden="true" />
        </FlexBox>
      </ContactIcon>
      <ContactIcon bgColor="#33CCFF">
        <FlexBox>
          <i className="fa fa-twitter fa-lg" aria-hidden="true" />
        </FlexBox>
      </ContactIcon>
      <ContactIcon bgColor="#E51670">
        <FlexBox>
          <i className="fa fa-slack fa-lg" aria-hidden="true" />
        </FlexBox>
      </ContactIcon>
      <ContactIcon bgColor="#00bdff">
        <FlexBox>
          <i className="fa fa-skype fa-lg" aria-hidden="true" />
        </FlexBox>
      </ContactIcon>
    </CommonContainer>
  );

  showContactInformation = (contactDetails) => (
    contactDetails.map((contact, index) => (
      <FlexBox key={index}>
        <GridBox gridWidth="25%" opacity={1} padding="12px 0px">
          {contact.name + ':'}
        </GridBox>
        <GridBox gridWidth="75%" marginLeft="5px" padding="12px 0px">
          {contact.details}
        </GridBox>
      </FlexBox>
    ))
  );
  
  contactInfoDashBoard = (contactDetails) => (
    <div className="boxShadow">
      {this.renderHeader('Contact Info')}
      <CommonContainer  padding="25px">
        { this.showContactInformation(contactDetails) }
      </CommonContainer>
      { this.socialIcon() }
    </div>
  );

  showBadges = (badgeDetails) => (
    badgeDetails.map((badge, index) => (
      <GridBox gridWidth="50%" key={index}>
        <div className="welnessBadge">
          <FlexBox>
            <div className="badgeBorder">
              <div className="badgeCount">
                {badge.value}
              </div>
            </div>
          </FlexBox>
          <div className="issue">
            { badge.issue}
          </div>
          <div className="issueDescription">
            {badge.issueDescription}
          </div>
        </div>
      </GridBox>
    ))
  );

  showOtherGoals = (otherGoalsData, padding) => (
    otherGoalsData.map((data, index) => (
      <div key={index}>
        <FlexBox>
          <GridBox gridWidth="13%" fontSize='15px' padding={padding}>
            { data.svg
              ?
              <StyledSvg fill={data.fill} viewBox={data.viewBox}>
                <path d={data.svg} />
              </StyledSvg>
              :
              this.getSvgComponent(data)
            }
          </GridBox>
          <GridBox gridWidth="60%" fontSize='14px' padding={padding}>
            <div className="goal-name">{ data.name}</div>
          </GridBox>
          <GridBox gridWidth="32%" textAlign="right" fontSize='14px' padding={padding}>
            { data.value}
          </GridBox>
        </FlexBox>
      </div>
    ))
  );
  wellnessAchievementDashboard = (badgeDetails, otherGoalsData) => (
    <div className="boxShadow">
      {this.renderHeader('Wellness Achievements')}
      <CommonContainer >
        <div className="wellnessHeader">
          <div>Top Badges</div>
          { this.showBadges(badgeDetails) }
        </div>
      </CommonContainer>
      <CommonContainer padding="10px 25px" borderTop>
        <div className="wellnessHeader">
          <div>Other Goals</div>
          { this.showOtherGoals(otherGoalsData, '5px 0px') }
        </div>
      </CommonContainer>
    </div>
  );

  showWellnessInitiative = (data) => (
    data.map((Wellness, index) => (
      <NewUserCard key={index} width="24px" padding="5px 25px">
        <EmployeeGrid gridWidth="10%" paddingTop>
          <StyledSvg fill={Wellness.fill} viewBox={Wellness.viewBox}>
            <path d={Wellness.svg} />
          </StyledSvg>
        </EmployeeGrid>
        <EmployeeGrid gridWidth="80%" paddingLeft={1} paddingTop>
          <div>{Wellness.name}</div>
          <div className="company">{Wellness.date}</div>
        </EmployeeGrid>
        <EmployeeGrid gridWidth="10%" textAlign={1} paddingTop>
          <span>
            { Wellness.tick
              ?
              <img src='/public/images/right-tick.png' alt="right-tick" />
              :
              <img src='/public/images/wrong-tick.png' alt="wrong-tick" />
            }
          </span>
        </EmployeeGrid>
      </NewUserCard>
    ))
  );

  wellnessInitiativesDashBoard = (wellnessInitiativeData) => (
    <div className="boxShadow">
      <HeaderContainer textAlign>
        <HeaderTitle>Wellness Initiatives</HeaderTitle>
      </HeaderContainer>
      <CommonContainer padding="0px">
        { this.showWellnessInitiative(wellnessInitiativeData) }
      </CommonContainer>
      <CommonContainer padding="25px">
        <GridBox gridWidth="70%" marginLeft="15%">
          <UserButton bgColor="#159fc9" padding="10px 7px" fontSize="10px" Align={1}>
            REMOVE EMPLOYEE
          </UserButton>
        </GridBox>
      </CommonContainer>
    </div>
  );

  render() {
    const { year, quarter } = this.state;
    const { history } = this.props;
    return(
      <EngagementTab>
        <HeaderEmployee>
          <EngagementContainer>
            <SplitEngagementSection gridWidth='50%'>
              <div className="flex">
                <div className="name">
                  Dylan Baylidge
                </div>
                <div className='goBack'>
                  <UserButton bgColor="lightgray" padding="8px 16px" height="auto" textColor="black" onClick={() => history.goBack()}>
                    <i className="fas fa-long-arrow-alt-left icon-button" /> Go Back
                  </UserButton>
                </div>
              </div>
            </SplitEngagementSection>
            <SplitEngagementSection gridWidth='50%' textAlign="right">
              <div className="float">
                <CustomDropDown title={ year || "Filter by Year"} id="filter by year" bigbutton='true'>
                  { this.setMenuItem(Year, 'year')}
                </CustomDropDown>
              </div>
              <div className="float">
                <CustomDropDown title={quarter || "Filter by Quarter"} id="filter-by-quarter" bigbutton='true'>
                  { this.setMenuItem(Quarter, 'quarter')}
                </CustomDropDown>
              </div>
            </SplitEngagementSection>
          </EngagementContainer>
        </HeaderEmployee>
        <EngagementContainer>
          <SplitEngagementSection gridWidth="36%" paddingRight={1}>
            { this.employeeInfoDashBoard(employee, TrainingCompleted) }
            { this.contactInfoDashBoard(contactDetails) }
          </SplitEngagementSection>
          <SplitEngagementSection gridWidth="36%" paddingRight={1}>
            { this.recognitionDashBoard(recognitionData) }
            { this.wellnessAchievementDashboard(badgeDetails, otherGoalsData) }
          </SplitEngagementSection>
          <SplitEngagementSection gridWidth="28%">
            { this.wellnessInitiativesDashBoard(wellnessInitiativeData) }
          </SplitEngagementSection>
        </EngagementContainer>
      </EngagementTab>
    );
  }
}

EmployeeDetailsPage.propTypes = {
  history: PropTypes.object
}

export default EmployeeDetailsPage;