import styled from 'styled-components';

const SplitEngagementSection = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '35%'};
  padding-right: ${({paddingRight}) => paddingRight ? '25px': '0px'};
  float: left;
  text-align: ${({ textAlign }) => textAlign ? textAlign : ''};
  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0px;
  }
  .boxShadow {
    box-shadow: 2px 3px 15px rgba(113, 109, 109, 0.5);
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  height: ${({gridHeight}) => gridHeight ? gridHeight: '100%'};
  background-color:  ${({bgColor}) => bgColor ? bgColor : 'white'}; 
  padding: ${({padding}) => padding ? padding : '10px'};
  border-top: ${({borderTop}) => borderTop ? '1px solid #ededed' : '' };
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: uppercase;
  float: left; 
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
  }
  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  .wellnessHeader {
    text-align: center;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ContactIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  text-transform: lowercase;
  background-color: ${({bgColor}) => bgColor ? bgColor : ''};
  color: white;
  font-size: 15px;
`;

const GridBox = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth : '100%'};
  display: inline-block;
  opacity: ${({opacity}) => opacity ? 0.4 : 1 };
  text-transform: capitalize;
  font-weight: normal;
  float: left;
  color: black;
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};;
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
  padding: ${({padding}) => padding ? padding : '10px 0px'};
  font-size: ${({fontSize}) => fontSize ? fontSize: '14px' };

  .goal-name {
    color: rgb(153,153,153);
  }
  .welnessBadge {
    font-weight: normal;
    text-transform: capitalize;
    text-align: center;
    .badgeBorder {
      height: 41px;
      width: 41px;
      border-radius: 50%;
      border: 1px solid rgb(239,104,39);
      padding: 1.5px;
      .badgeCount {
        height: 35px;
        width: 35px;
        margin-top: 0.5px;
        margin-left: 0.5px;
        border-radius: 50%;
        background-color: rgb(239,104,39);
        align-items: center;
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
      }
    }
    .issue {
      opacity: 0.5;
      font-size: 12px;
      margin-top: 5px;
    }
    .issueDescription {
      font-size: 16px;
      margin-top: 5px;
    }
  }
  > svg {
    height: 25px;
  }
`;

const ImageAndProfile = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  > div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e8e7e7;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > img {
      position: relative;
      top: 10px;
      height: 100px;
    }
  }
`;

const HeaderEmployee = styled.div`
  padding: 5px 0px 10px;
  display: flex;
  .flex{
    display: flex;
    align-items: center;
  }
  .float {
    margin-left: 10px;
    text-align: right;
    margin-bottom: 10px;
    display: inline-block;
    @media(max-width: 600px) {
      display: inline-block;
      margin-top: 15px;
      float: left;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .name {
    font-size: 26px;
    opacity: 0.5;
    font-weight: normal;
    display: inline-block;
  }
  .goBack {
    display: inline-block;
    margin-left: 10px;
  }
  .icon-button {
    margin-right: 6px;
  }
`;
export { SplitEngagementSection, CommonContainer, FlexBox, ContactIcon, GridBox, ImageAndProfile, HeaderEmployee}